import {useEffect, useState} from "react";
import {api} from "../../../../services/backend";
import useErrorHandler from "../../../../hooks/api/useErrorHandler";
import {URLSearchParamsAdapter} from "../../../../adapters/url-search-params";

export default function useAnnouncementsCount() {
  const [count, setCount] = useState(undefined);
  const errorHandler = useErrorHandler();

  useEffect(() => {
    fetchAnnouncementsCount();
  }, []);

  return {count, reload: fetchAnnouncementsCount};

  async function fetchAnnouncementsCount() {
    try {
      const response = await api.announcement.count(
        URLSearchParamsAdapter.fromObject({
          isViewed: false,
        })
      );
      
      setCount(response.announcements);
    } catch (error) {
      errorHandler(error);
    }
  }
}
