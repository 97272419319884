import {useHistory} from "react-router-dom";
import {BadRequestError, HttpError, RequestError, UnauthorizedError} from "../../services/backend";
import {useDispatch} from "react-redux";
import {addFlashMessage} from "../../storage/redux/slices/flashMessages";
import {TYPE_ERROR} from "../../components/flash-message/FlashMessages";
import {resetProfile} from "../../storage/redux/slices/user";

export default function useErrorHandler() {
  const history = useHistory();
  const dispatch = useDispatch();

  return (error, options = {}) => {
    if (error instanceof BadRequestError) {
      setErrors(error.cause.data, options);
    } else if (error instanceof UnauthorizedError) {
      dispatch(resetProfile());
      history.push('/sign-in');
    } else if (error instanceof HttpError) {
      dispatch(addFlashMessage({
        message: error.message,
        type: TYPE_ERROR,
      }));
    } else if (error instanceof RequestError) {
      console.log(error.message);
    }
  };

  function setErrors(errorsData, options) {
    const {
      formName = null,
      formik = null, // TODO: переименовать formik в formHandler
      beforeSetErrors = (errors) => errors,
      afterSetErrors = () => null,
    } = options;

    let errors = null === formName ? errorsData : errorsData[formName];

    errors = beforeSetErrors(errors);

    if (null !== formik && undefined !== errors && 0 !== Object.keys(errors).length) {
      formik.setErrors(errors);
    }

    afterSetErrors(errors);
  }
}
