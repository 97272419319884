const toString = (value) => {
  if (typeof value === 'boolean') {
    return value ? '1' : '0';
  }

  return value.toString();
};

const URLSearchParamsAdapter = {
  clone(searchParams) {
    const newSearchParams = new URLSearchParams();

    for (const [key, value] of searchParams.entries()) {
      newSearchParams.set(key, value);
    }

    return newSearchParams;
  },
  fromObject(objectValue = {}) {
    const searchParams = new URLSearchParams();
    return this.appendFromObject(searchParams, objectValue);
  },
  toObject(searchParams) {
    const objectValue = {};

    for (const [key, value] of searchParams.entries()) {
      objectValue[key] = value;
    }

    return objectValue;
  },
  appendFromObject(searchParams, objectValue) {
    Object.keys(objectValue).forEach((key) => {
      searchParams.set(key, toString(objectValue[key]));
    });

    return searchParams;
  },
  fromString(stringValue) {
    return new URLSearchParams(stringValue);
  },
  toString(searchParams) {
    return searchParams.toString();
  },
  fromMixed(options) {
    const {stringValue = '', objectValue = {}} = options;
    const searchParams = this.fromString(stringValue);

    return this.appendFromObject(searchParams, objectValue);
  },
  toArray(searchParams) {
    return searchParams.getAll();
  },
};

export {URLSearchParamsAdapter};
